import React from "react";
import "./index.scss";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import OTP from "./components/pages/OTP";
import Layout from "./components/layout/Layout";
import Dashboard from "./components/pages/Dashboard";
import Users from "./components/pages/Users";
import Adduser from "./components/pages/Adduser";
import Edituser from "./components/pages/Edituser";
import ProfileSettings from "./components/pages/ProfileSettings";
import Viewuser from "./components/pages/Viewuser";
import Allpages from "./components/pages/Allpages";
import Contactqueries from "./components/pages/Contactqueries";
import Reports from "./components/pages/Reports";
import SubscriptionPackages from "./components/pages/SubscriptionPackages";
import Dates from "./components/pages/Dates";
import Datedetail from "./components/pages/Datedetail";
import ConciergeRequests from "./components/pages/ConciergeRequests";
import Viewpayment from "./components/pages/Viewpayment";
import UserDatedetail from "./components/pages/UserDatedetail";
import Viewrequests from "./components/pages/Viewrequests";
import Category from "./components/pages/Category";
import Subcategory from "./components/pages/Subcategory";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./navigation/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PAGES from "./constants/pages";
import AuthRoute from "./navigation/AuthRoute";
import NotFound from "./components/pages/NotFound";

function App() {
  const [loading, setLoading] = React.useState(false);

  console.log("data")

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#060606" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <Router basename={process.env.REACT_APP_BASENAME}>
          <ToastContainer />
          <Routes>
            <Route
              exact
              path={PAGES.login}
              element={
                <AuthRoute>
                  <Login />
                </AuthRoute>
              }
            />
            <Route
              exact
              path={PAGES.forgotPassword}
              element={
                <AuthRoute>
                  <ForgotPassword />
                </AuthRoute>
              }
            />
            <Route
              exact
              path={PAGES.otp}
              element={
                <AuthRoute>
                  <OTP />
                </AuthRoute>
              }
            />
            <Route
              exact
              path={PAGES.resetPassword}
              element={
                <AuthRoute>
                  <ResetPassword />
                </AuthRoute>
              }
            />
            <Route
              path={PAGES.dashboard}
              element={
                <ProtectedRoute>
                  <Layout title="Dashboard">
                    <Dashboard />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.users}
              element={
                <ProtectedRoute>
                  <Layout title="Users">
                    <Users />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.addUser}
              element={
                <ProtectedRoute>
                  <Layout title="Add User">
                    <Adduser />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.editUser}
              element={
                <ProtectedRoute>
                  <Layout title="Edit User">
                    <Edituser />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.viewUser}
              element={
                <ProtectedRoute>
                  <Layout title="User Details">
                    <Viewuser />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.allPages}
              element={
                <ProtectedRoute>
                  <Layout title="All Pages">
                    <Allpages />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.contactQueries}
              element={
                <ProtectedRoute>
                  <Layout title="Contact Queries">
                    <Contactqueries />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.reports}
              element={
                <ProtectedRoute>
                  <Layout title="Reports">
                    <Reports />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.subscriptionPackages}
              element={
                <ProtectedRoute>
                  <Layout title="Subscription Packages">
                    <SubscriptionPackages />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.dates}
              element={
                <ProtectedRoute>
                  <Layout title="Dates">
                    <Dates />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.dateDetail}
              element={
                <ProtectedRoute>
                  <Layout title="Date details">
                    <Datedetail />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.conciergeRequests}
              element={
                <ProtectedRoute>
                  <Layout title="Concierge Requests">
                    <ConciergeRequests />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.viewRequests}
              element={
                <ProtectedRoute>
                  <Layout title="Concierge Request Details">
                    <Viewrequests />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.viewPayment}
              element={
                <ProtectedRoute>
                  <Layout title="Payment Details">
                    <Viewpayment />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.userDateDetail}
              element={
                <ProtectedRoute>
                  <Layout title="Date Details">
                    <UserDatedetail />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.category}
              element={
                <ProtectedRoute>
                  <Layout title="Categories">
                    <Category />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.subcategory}
              element={
                <ProtectedRoute>
                  <Layout title="Subcategories">
                    <Subcategory />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={PAGES.profileSettings}
              element={
                <ProtectedRoute>
                  <Layout title="Profile Settings">
                    <ProfileSettings />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />

          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
