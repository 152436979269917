import localStorage from "redux-persist/es/storage";
import config from "../constants/config";

function formatDate(timestamp) {
  // Convert to milliseconds if the timestamp is in seconds
  if (timestamp < 10000000000) {
    timestamp *= 1000;
  }
  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function addOneMonth(dateTimeString) {
  // Extract the date part
  const datePart =  dateTimeString && dateTimeString?.split(" ")[0];

  // Convert to Date object
  const dateObj = new Date(datePart);

  // Check if the date is valid
  if (isNaN(dateObj.getTime())) {
    throw new Error("Invalid date format");
  }

  // Add one month
  dateObj.setMonth(dateObj.getMonth() + 1);

  // Format the new date to YYYY-MM-DD
  const newDate = dateObj.toISOString()?.split("T")[0];

  return newDate;
}

const uploadFiles = async (file) => {
  // Check if the file type is allowed
  const allowedTypes = ["image/png", "image/jpeg", "image/webp"];
  if (!allowedTypes.includes(file.type)) {
    alert("Only .png, .jpg, or .webp images are allowed");
    return;
  }

  const authToken = localStorage.getItem("authToken");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${authToken}`);

  const formdata = new FormData();
  formdata.append("image", file, file.name);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${config.API_URL}/auth/file-upload`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const formattedDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function convertDateFormat(inputDate) {
 // Split the date string into day, month, and year
 const parts = inputDate.split('/');
 const day = parts[0];
 const month = parts[1];
 const year = parts[2];

 // Format the date as yyyy/mm/dd
 const formattedDate = `${year}-${month}-${day}`;

 return formattedDate;
}

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

export { formatDate, addOneMonth, uploadFiles,formattedDate ,convertDateFormat,debounce};
