import { END_POINTS } from "../../../constants/endpoints";
import { api } from "../api";

export const homeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: ({ offset, limit,search }) => ({
        url: `${END_POINTS.users}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
    }),
    getUsersDetails: build.query({
      query: (data) => (
        {
          url: END_POINTS.userDetails.replace("{}", data?.id?.id) + data?.type,
          method: "GET",
        }
      ),
    }),
    dashboardInfo: build.query({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),
    contactQueriesList: build.query({
      query: ({offset,limit,search}) => ({
        url:`${END_POINTS.contactQueriesList}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
    }),
    contactQueriesDelete: build.query({
      query: ({ id }) => ({
        url: END_POINTS.contactQueriesDelete + id,
        method: "GET",
      }),
    }),
    reportList: build.query({
      query: ({offset,limit,search}) => ({
        url: `${END_POINTS.reportList}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
    }),
    reportDelete: build.query({
      query: ({ id }) => ({
        url: END_POINTS.reportDelete + id,
        method: "GET",
      }),
    }),
    datesList: build.query({
      query: ({ offset, limit,search }) => ({
        url: `${END_POINTS.datesList}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
    }),
    dateRequestList: build.query({
      query: ({offset,limit,search}) => ({
        url: `${END_POINTS.dateRequestList}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
      providesTags: ['dateRequestList']
    }),
    plansList: build.query({
      query: () => ({
        url: END_POINTS.plansList,
        method: "GET",
      }),
    }),
    updatePlans: build.mutation({
      query: (body) => ({
        url: END_POINTS.updatePlans,
        method: "POST",
        body,
      }),
    }),
    categoriesList: build.query({
      query: ({offset,limit,search}) => ({
        url:  `${END_POINTS.categoriesList}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
    }),
    createCategory: build.mutation({
      query: (body) => ({
        url: END_POINTS.createCategory,
        method: "POST",
        body,
      }),
    }),
    updateCategory: build.mutation({
      query: (body) => ({
        url: END_POINTS.updateCategory,
        method: "POST",
        body,
      }),
    }),
    subCategoriesList: build.query({
      query: ({ offset, limit,search }) => ({
        url: `${END_POINTS.subCategoriesList}?offset=${offset}&limit=${limit}&search=${search ? `${search}` : ''}`,
        method: "GET",
      }),
    }),
    createSubCategory: build.mutation({
      query: (body) => ({
        url: END_POINTS.createSubCategory,
        method: "POST",
        body,
      }),
    }),
    updateSubCategory: build.mutation({
      query: (body) => ({
        url: END_POINTS.updateSubCategory,
        method: "POST",
        body,
      }),
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: END_POINTS.createUser,
        method: "POST",
        body,
      }),
    }),
    updateUser: build.mutation({
      query: (body) => ({
        url: END_POINTS.updateUser,
        method: "POST",
        body,
      }),
    }),
    allPagesList: build.query({
      query: () => ({
        url: END_POINTS.allPagesList,
        method: "GET",
      }),
    }),
    createPage: build.mutation({
      query: (body) => ({
        url: END_POINTS.createPage,
        method: "POST",
        body,
      }),
    }),
    updatePage: build.mutation({
      query: (body) => ({
        url: END_POINTS.updatePage,
        method: "POST",
        body,
      }),
    }),
    deletePage: build.query({
      query: ({ id }) => ({
        url: END_POINTS.deletePage + id,
        method: "GET",
      }),
    }),
    conciergeApprove: build.mutation({
      query: (body) => ({
        url: END_POINTS.conciergeApprove,
        method: "POST",
        body,
      }),
      invalidatesTags: ['dateRequestList'], 
    }),
    conciergeReject: build.mutation({
      query: (body) => ({
        url: END_POINTS.conciergeReject,
        method: "POST",
        body,
      }),
      invalidatesTags: ['dateRequestList'], 
    }),
    getActiveCategoryList: build.query({
      query: () => ({
        url: END_POINTS.getActiveCategoryList,
        method: "GET",
      }),
    }),
    getActiveSubCategoryList: build.query({
      query: ({ subCategoryId }) => ({
        url: `${END_POINTS.getActiveSubCategoryList}${subCategoryId}`,
        method: "GET",
      }),
    }),
    deleteUser: build.mutation({
      query: (body) => ({
        url: END_POINTS.deleteUser,
        method: "DELETE",
        body
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUsersDetailsQuery,
  useDashboardInfoQuery,
  useReportListQuery,
  useReportDeleteQuery,
  useContactQueriesListQuery,
  useContactQueriesDeleteQuery,
  useDatesListQuery,
  useDateRequestListQuery,
  usePlansListQuery,
  useUpdatePlansMutation,
  useCategoriesListQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useSubCategoriesListQuery,
  useCreateSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useCreateUserMutation,
  useAllPagesListQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageQuery,
  useConciergeApproveMutation,
  useConciergeRejectMutation,
  useGetActiveCategoryListQuery,
  useGetActiveSubCategoryListQuery,
  useUpdateUserMutation,
  useDeleteUserMutation
} = homeApi;
